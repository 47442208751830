<template>
  <div>
    全部服务
  </div>
</template>

<script>
export default {
  name: 'all-components'
}
</script>

<style scoped>

</style>
